import { TFunction } from "i18next";
import * as Yup from "yup";
import {
  EndRecurrenceEnum,
  IEventImage,
  NEW_PLACE,
  RepeatPeriodEnum,
  TicketTypeEnum,
} from "../types";

export const validationSchemaStep1 = (t: TFunction) =>
  Yup.object().shape({
    title: Yup.string()
      .required(t("firstStep.required"))
      .min(5, t("firstStep.short"))
      .max(50, t("firstStep.long")),
    // type: Yup.string().required(t("firstStep.required")),
    description: Yup.string()
      .min(100, t("firstStep.short"))
      .required(t("firstStep.required")),
    startDate: Yup.date()
      .min(new Date(), t("firstStep.Start date cannot be in the past"))
      .required(t("firstStep.required")),
    endDate: Yup.date()
      .min(Yup.ref("startDate"), t("firstStep.greaterThenStart"))
      .required(t("firstStep.required")),
    poster: Yup.mixed()
      .required(t("firstStep.required"))
      .test(
        "poster",
        `${t("firstStep.maxSize")} 5 MB`,
        (value: File | null) => {
          return value ? value.size <= 5 * 1024 * 1024 : true;
        }
      ),
    galery: Yup.mixed()
      .nullable()
      .test({
        name: "galery",
        test: function (galery: IEventImage[] | null) {
          if (
            galery === null ||
            (Array.isArray(galery) && galery.length === 0)
          ) {
            return true; // Valid if null or empty array
          }
          const invalidItem = galery.find(
            (item) => item.file.size >= 5 * 1024 * 1024
          );
          if (invalidItem) {
            const message = `${t("firstStep.maxSize")} 5 MB. ${t("firstStep.Change gallery image")} ${galery.indexOf(invalidItem) + 1}`;
            return this.createError({ message });
          }

          return true; // Return true if no error is found
        },
      }),
    banner: Yup.mixed()
      .nullable()
      .test(
        "banner",
        `${t("firstStep.maxSize")} 10 MB`,
        (value: IEventImage | null) => {
          return value ? value.file.size <= 1 * 1024 * 1024 : true;
        }
      ),
    categories: Yup.array()
      .min(1, t("firstStep.required"))
      .required(t("firstStep.required")),
    country: Yup.string().when("isOnline", {
      is: (isOnline: boolean) => !isOnline,
      then: (schema) => schema.required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    city: Yup.string().when("isOnline", {
      is: (isOnline: boolean) => !isOnline,
      then: (schema) => schema.required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    place: Yup.string().when("isOnline", {
      is: (isOnline: boolean) => !isOnline,
      then: (schema) => schema.required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    placeName: Yup.string().test({
      name: "placeName",
      test: function (placeName: string) {
        const isOnline: boolean = this.parent.isOnline;
        const place: string = this.parent.place;
        if (isOnline || place !== NEW_PLACE) {
          return true;
        }
        return placeName && placeName.trim() !== "";
      },
      message: t("firstStep.required"),
    }),
    address: Yup.string().test({
      name: "address",
      test: function (address: string) {
        const isOnline: boolean = this.parent.isOnline;
        const place: string = this.parent.place;
        if (isOnline || place !== NEW_PLACE) {
          return true;
        }
        return address && address.trim() !== "";
      },
      message: t("firstStep.required"),
    }),
    eventInvitationLink: Yup.string().when("isOnline", {
      is: (isOnline: boolean) => isOnline,
      then: (schema) =>
        schema.url(t("firstStep.invalidUrl")).required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    guidelinesPlatformUse: Yup.string().when("isOnline", {
      is: (isOnline: boolean) => isOnline,
      then: (schema) => schema.max(255, t("firstStep.long")).nullable(),
      otherwise: (schema) => schema.notRequired(),
    }),
    isRecurring: Yup.boolean(),
    recurrence: Yup.mixed().when("isRecurring", {
      is: (isRecurring: boolean) => isRecurring,
      then: () => recurrenceSchema(t),
    }),
    timezoneId: Yup.string().required(t("firstStep.required")),
    repeats: Yup.string(),
  });

const recurrenceSchema = (t: TFunction) =>
  Yup.object().shape({
    endRecurrence: Yup.string(),
    repeatsEvery: Yup.number().when(["$repeats"], {
      is: (repeats: RepeatPeriodEnum) => repeats === RepeatPeriodEnum.Custom,
      then: (schema) =>
        schema
          .min(1, `${t("secondStep.greater")} 0`)
          .required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    onDate: Yup.date().when(["endRecurrence", "$repeats"], {
      is: (endRecurrence: EndRecurrenceEnum, repeats: RepeatPeriodEnum) =>
        repeats === RepeatPeriodEnum.Custom &&
        endRecurrence === EndRecurrenceEnum.OnDate,
      then: (schema) =>
        schema
          .test({
            test: function (onDate) {
              return (
                new Date(onDate) > new Date(this.options.context.startDate)
              );
            },
            message: t("firstStep.greaterThenStart"),
          })
          .required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    endAfterOccurences: Yup.number().when(["endRecurrence", "$repeats"], {
      is: (endRecurrence: EndRecurrenceEnum, repeats: RepeatPeriodEnum) =>
        repeats === RepeatPeriodEnum.Custom &&
        endRecurrence === EndRecurrenceEnum.After,
      then: (schema) =>
        schema
          .min(1, `${t("secondStep.greater")} 0`)
          .required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export const validationSchemaStep2 = (t: TFunction) =>
  Yup.object().shape({
    isReturnable: Yup.boolean(),
    contacts: Yup.array()
      .of(contactSchema(t))
      .when("isReturnable", ([isReturnable]: boolean[], schema) => {
        return isReturnable ? schema : Yup.array();
      }),
    tickets: Yup.array().of(ticketSchema(t)),
    returnDescription: Yup.string().when("isReturnable", {
      is: (isReturnable: boolean) => isReturnable,
      then: (schema) =>
        schema
          .min(1, t("firstStep.short"))
          .max(1000, t("firstStep.long"))
          .required(t("firstStep.required")),
    }),
  });

const contactSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("secondStep.invalidEmail"))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("secondStep.invalidEmail"))
      .required(t("firstStep.required")),
    phone: Yup.string()
      .min(5, t("firstStep.short"))
      .required(t("firstStep.required")),
  });

const ticketSchema = (t: TFunction) =>
  Yup.object().shape({
    quantity: Yup.number().when("active", {
      is: (active: boolean) => active,
      then: (schema) =>
        schema
          .min(1, `${t("secondStep.greater")} 0`)
          .required(t("firstStep.required")),
    }),
    price: Yup.number().when(["active", "ticketType"], {
      is: (active: boolean, ticketType: string) => {
        return active === true && ticketType !== "free";
      },
      then: (schema) =>
        schema
          .positive(`${t("secondStep.greater")} 1`)
          .required(t("firstStep.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    limitQuantity: Yup.number().when("active", {
      is: (active: boolean) => active,
      then: (schema) =>
        schema
          .min(1, `${t("secondStep.greater")} 1`)
          .test({
            name: "limitQuantity",
            test: function (limitQuantity) {
              const { quantity } = this.parent;
              const isValid =
                quantity <= 20
                  ? limitQuantity <= quantity
                  : limitQuantity <= 20;

              if (!isValid) {
                const message = `${t("secondStep.less")} ${quantity <= 20 ? t("secondStep.quantity") : 20}`;
                return this.createError({ message });
              }

              return true;
            },
          })
          .required(t("firstStep.required")),
    }),
    description: Yup.string().when("active", {
      is: (active: boolean) => active,
      then: (schema) =>
        schema.max(
          200,
          t("secondStep.Description should not be more than 200 characters")
        ),
    }),
    date: Yup.date()
      .when(["active", "ticketType"], {
        is: (active: boolean, ticketType: string) => {
          return (
            active === true &&
            (ticketType === TicketTypeEnum.EarlyBird ||
              ticketType === TicketTypeEnum.LastMinute)
          );
        },
        then: (schema) => schema.required(t("firstStep.required")),
        otherwise: (schema) => schema.notRequired(),
      })
      .test({
        test: function (date) {
          const startDate: Date = this.options.context.startDate;
          const ticketType: string = this.parent.ticketType;
          if (
            ticketType === TicketTypeEnum.LastMinute ||
            ticketType === TicketTypeEnum.EarlyBird
          ) {
            return date < new Date(startDate);
          }
          return true;
        },
        message: `${t("secondStep.less")} ${t("firstStep.startDate")}`,
      }),
  });

export const validationSchemaStep3 = (t: TFunction) =>
  Yup.object().shape({
    isPayOnline: Yup.boolean(),
    ticketAddresses: Yup.array().when("isPayOnline", {
      is: (isPayOnline: boolean) => !isPayOnline,
      then: (schema) =>
        schema.of(Yup.string().required(t("firstStep.required"))),
      otherwise: (schema) => schema.notRequired(),
    }),
    merchantContacts: Yup.array().when("isPayOnline", {
      is: (isPayOnline: boolean) => !isPayOnline,
      then: (schema) => schema.of(merchantContactsSchema(t)),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

const merchantContactsSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("secondStep.invalidEmail"))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("secondStep.invalidEmail"))
      .required(t("firstStep.required")),
    phone: Yup.string().required(t("firstStep.required")),
  });

export const bankDirectSchema = (t: TFunction) =>
  Yup.object().shape({
    bankDirect: Yup.object().shape({
      email: Yup.string()
        .email(t("secondStep.invalidEmail"))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("secondStep.invalidEmail"))
        .required(t("firstStep.required")),
      phone: Yup.string().required(t("firstStep.required")),
      address: Yup.string().required(t("firstStep.required")),
      city: Yup.string().required(t("firstStep.required")),
      postalCode: Yup.number().required(t("firstStep.required")),
      countryResidence: Yup.string().required(t("firstStep.required")),
      countryAccount: Yup.string().required(t("firstStep.required")),
      bankName: Yup.string().required(t("firstStep.required")),
      holderName: Yup.string().required(t("firstStep.required")),
    }),
  });

export const bankConnectSchema = (t: TFunction) =>
  Yup.object().shape({
    bankConnect: Yup.object().shape({
      currency: Yup.string().required(t("firstStep.required")),
      holderName: Yup.string().required(t("firstStep.required")),
      iban: Yup.string().when("isIban", {
        is: (isIban: boolean) => isIban,
        then: (schema) => schema.required(t("firstStep.required")),
      }),
      accountNumber: Yup.string().when("isNumber", {
        is: (isNumber: boolean) => isNumber,
        then: (schema) => schema.required(t("firstStep.required")),
      }),
      swiftBic: Yup.string().when("isSwift", {
        is: (isSwift: boolean) => isSwift,
        then: (schema) => schema.required(t("firstStep.required")),
      }),
      routingNumber: Yup.string().when("isRouting", {
        is: (isRouting: boolean) => isRouting,
        then: (schema) => schema.required(t("firstStep.required")),
      }),
    }),
  });
