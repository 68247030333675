import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { useFormikContext } from "formik";
import { IEntity, SelectOption } from "../../../types";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../components/inputs/select";
import InputText from "../../../components/inputs/inputText";
import { usePaymentCountryQuery } from "../../../qraphql/event.hooks";
import { InfoCircle } from "react-bootstrap-icons";

interface IPaymentConnectProps {
  handlePaymentConnectLoading: (isLoading: boolean) => void;
}
export default function PaymentConnect({
  handlePaymentConnectLoading,
}: IPaymentConnectProps) {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<IEntity>();
  const { data: paymentCountryData, loading: paymentCountryLoading } =
    usePaymentCountryQuery({
      variables: {
        countryCode: values.bankConnect.selectedStripeCountryCode,
      },
      fetchPolicy: "network-only",
      onCompleted(data) {
        const { iban, number, routing, swift } = data.paymentCountry;
        setFieldValue("bankConnect.isIban", iban);
        setFieldValue("bankConnect.isNumber", number);
        setFieldValue("bankConnect.isRouting", routing);
        setFieldValue("bankConnect.isSwift", swift);
      },
    });

  const optionsCurrencies =
    paymentCountryData?.paymentCountry.currencies?.map((item) => ({
      value: item,
      label: item,
    })) || [];

  useEffect(() => {
    handlePaymentConnectLoading(paymentCountryLoading);
  }, [handlePaymentConnectLoading, paymentCountryLoading]);

  const Header = () => {
    return (
      <>
        <div className="fw-bold fs-4 mb-3">{t("thirdStep.bankAccInfo")}</div>
        <div className="bg-light-gray text-info p-3 rounded d-flex mb-3">
          <div>
            <InfoCircle className="pe-2 fs-4" height={26} width={26} />
          </div>
          {t(
            "thirdStep.Please fill in your bank account info carefully in English."
          )}
        </div>
      </>
    );
  };

  if (paymentCountryLoading) {
    return (
      <>
        <Header />
        <div className="text-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </>
    );
  }
  return (
    <>
      <Header />
      <Row className="g-3 gy-2 pb-3">
        <Col lg={6}>
          <SelectInput
            options={optionsCurrencies}
            label={t("secondStep.currency")}
            name={`bankConnect.currency`}
            placeholder={t("thirdStep.selectCurrency")}
            onBlur={() => setFieldTouched("bankConnect.currency", true)}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "bankConnect.currency",
                selectedOption ? selectedOption.value : ""
              );
            }}
            value={optionsCurrencies.find(
              (option) => option.value === values.bankConnect.currency
            )}
          />
        </Col>
        <Col lg={6}>
          <FloatingLabel label={t("thirdStep.bankCountry")}>
            <Form.Control
              value={paymentCountryData?.paymentCountry.name}
              disabled
            />
          </FloatingLabel>
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("thirdStep.holderName")}
            name={`bankConnect.holderName`}
          />
        </Col>
        {values.bankConnect.isNumber && (
          <Col lg={6}>
            <InputText
              placeholder={t("thirdStep.Account Number")}
              name={`bankConnect.accountNumber`}
            />
          </Col>
        )}
        {values.bankConnect.isIban && (
          <Col lg={6}>
            <InputText
              placeholder={t("thirdStep.iban")}
              name={`bankConnect.iban`}
            />
          </Col>
        )}
        {values.bankConnect.isSwift && (
          <Col lg={6}>
            <InputText
              placeholder={t("thirdStep.SWIFT/BIC")}
              name={`bankConnect.swiftBic`}
            />
          </Col>
        )}
        {values.bankConnect.isRouting && (
          <Col lg={6}>
            <InputText
              placeholder={t("thirdStep.Routing Number")}
              name={`bankConnect.routingNumber`}
            />
          </Col>
        )}
      </Row>
    </>
  );
}
