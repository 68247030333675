import React from "react";
import { Col, Row } from "react-bootstrap";
import RadioBoxWithImage from "../../../components/inputs/radioBoxWithImage";
import SingleEventImg from "./img/SingleEvent";
import RecurringEventImg from "./img/RecurringEvent";
import SelectInput from "../../../components/inputs/select";
import CustomRecurrenceSettings from "./CustomRecurrenceSettings";
import { useFormikContext } from "formik";
import { IEntity, RepeatPeriodEnum, SelectOption } from "../../../types";
import { useTranslation } from "react-i18next";
import { getDayNumber } from "../../../helpers";

export default function EventType() {
  const { t, i18n } = useTranslation();
  const { setFieldValue, values } = useFormikContext<IEntity>();

  const date = new Date(values.startDate);
  const dayName = date.toLocaleDateString(
    `${i18n.language}-${i18n.language.toLocaleUpperCase()}`,
    { weekday: "long" }
  );
  const dayOfMonth = date.toLocaleDateString(
    `${i18n.language}-${i18n.language.toLocaleUpperCase()}`,
    { day: "numeric" }
  );
  const monthName = date.toLocaleDateString(
    `${i18n.language}-${i18n.language.toLocaleUpperCase()}`,
    { month: "long" }
  );

  const repeatsOptions = [
    {
      label: t("firstStep.Daily"),
      value: RepeatPeriodEnum.Daily,
    },
    {
      label: `${t("firstStep.Weekly on")} ${dayName}`,
      value: RepeatPeriodEnum.Weekly,
    },
    {
      label: `${t("firstStep.Monthly on")} ${getDayNumber(date, t).nameCount} ${dayName}`,
      value: RepeatPeriodEnum.Monthly,
    },
    {
      label: `${t("firstStep.Annually on")} ${monthName} ${dayOfMonth}`,
      value: RepeatPeriodEnum.Yearly,
    },
    {
      label: t("firstStep.Every weekday (Monday to Friday)"),
      value: RepeatPeriodEnum.EveryWeekday,
    },
    {
      label: t("firstStep.customRecurrence"),
      value: RepeatPeriodEnum.Custom,
    },
  ];

  return (
    <>
      <Row className="gx-3 gy-2 mb-3">
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("firstStep.singleEvent")}
            name="isRecurring"
            id="inline-radio-single"
            img={<SingleEventImg />}
            checked={!values.isRecurring}
            onChange={() => setFieldValue("isRecurring", false)}
          />
        </Col>
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("firstStep.recurringEvent")}
            name="isRecurring"
            id="inline-radio-recurring"
            img={<RecurringEventImg />}
            checked={values.isRecurring}
            onChange={() => setFieldValue("isRecurring", true)}
          />
        </Col>
      </Row>
      {values.isRecurring && (
        <>
          <SelectInput
            options={repeatsOptions}
            placeholder={t("firstStep.selectRepeats")}
            label={t("firstStep.repeats")}
            name="repeats"
            className="mb-3"
            value={repeatsOptions.find(
              (option) => option.value === values.repeats
            )}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "repeats",
                selectedOption ? selectedOption.value : ""
              );
            }}
          />
          {values.repeats === RepeatPeriodEnum.Custom && (
            <CustomRecurrenceSettings />
          )}
        </>
      )}
    </>
  );
}
