import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Trash, QuestionCircle } from "react-bootstrap-icons";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import InputNumber from "../../../components/inputs/inputNumber";
import SelectInput from "../../../components/inputs/select";
import InputTextArea from "../../../components/inputs/inputTextArea";
import InputDateTime from "../../../components/inputs/inputDateTime";
import { useFormikContext } from "formik";
import { IEntity, SelectOption, TicketTypeEnum } from "../../../types";
import { ITicketTypesTitles } from "..";
import { useGetCurrenciesQuery } from "../../../qraphql/event.hooks";

interface ITicketTypeProps {
  ticketTypesTitles: ITicketTypesTitles;
}

const InfoTooltip = () => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltipExportCsv" className="bg-dark">
          <span>{t("secondStep.maxTickets")}</span>
        </Tooltip>
      }
    >
      <QuestionCircle
        className="position-absolute end-0 translate-middle me-4"
        style={{ zIndex: 200, top: 36 }}
      />
    </OverlayTrigger>
  );
};

export default function TicketType({ ticketTypesTitles }: ITicketTypeProps) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<IEntity>();
  const { data: currenciesData } = useGetCurrenciesQuery();

  const currenciesOptions = (
    currenciesData?.currencies.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    })) || []
  ).sort((a, b) => a.label.localeCompare(b.label));

  const handleChangeCurrency = (selectedOption: SelectOption | null) => {
    values.tickets.forEach((_, index) => {
      setFieldValue(
        `tickets[${index}].currency`,
        selectedOption ? selectedOption.label : ""
      );
    });
  };

  // deactivate EarlyBird and LastMinute tickets for recurring events
  useEffect(() => {
    if (!values.isRecurring) return;

    const updateTicketActiveState = (ticketType: TicketTypeEnum) => {
      const ticketIndex = values.tickets.findIndex(
        (item) => item.ticketType === ticketType
      );
      if (ticketIndex !== -1 && values.tickets[ticketIndex].active) {
        setFieldValue(`tickets[${ticketIndex}].active`, false);
      }
    };

    updateTicketActiveState(TicketTypeEnum.EarlyBird);
    updateTicketActiveState(TicketTypeEnum.LastMinute);
  }, []);

  return (
    <>
      {values.tickets
        .filter((ticket) => ticket.active)
        .map((item) => {
          const itemIndex = values.tickets.findIndex(
            (el) => el.ticketType === item.ticketType
          );
          return (
            <div
              className="border rounded-3 border-sec-background mt-3"
              key={item.ticketType}
            >
              <div className="d-flex justify-content-between align-items-center p-3 bg-sec-background-light">
                <div className="fw-bold fs-5">
                  {ticketTypesTitles[item.ticketType]}
                </div>
                <Button
                  variant="outline"
                  onClick={() =>
                    setFieldValue(
                      `tickets[${values.tickets.findIndex(
                        (t) => t.ticketType === item.ticketType
                      )}].active`,
                      false
                    )
                  }
                >
                  <Trash />
                </Button>
              </div>
              <div className="p-4">
                {item.ticketType === TicketTypeEnum.EarlyBird ||
                item.ticketType === TicketTypeEnum.LastMinute ? (
                  <Row className="g-3 mb-3">
                    <Col xs={12} className="field-ticket">
                      <InputNumber
                        name={`tickets[${itemIndex}].quantity`}
                        placeholder={t("secondStep.quantity")}
                        min={1}
                      />
                    </Col>
                    <Col xs={12} className="field-ticket">
                      <div className="position-relative hide-arrows">
                        <InputNumber
                          name={`tickets[${itemIndex}].limitQuantity`}
                          placeholder={t("secondStep.limitQuantity")}
                          min={1}
                          max={20}
                        />
                        <InfoTooltip />
                      </div>
                    </Col>

                    <Col xs={6} className="field-ticket">
                      <InputNumber
                        name={`tickets[${itemIndex}].price`}
                        placeholder={t("secondStep.price")}
                        min={0}
                      />
                    </Col>
                    <Col xs={6} className="field-ticket">
                      <SelectInput
                        options={currenciesOptions}
                        label={t("secondStep.currency")}
                        name={`tickets[${itemIndex}].currency`}
                        value={currenciesOptions.find(
                          (option) =>
                            option.label === values.tickets[itemIndex].currency
                        )}
                        onChange={(selectedOption: SelectOption | null) => {
                          handleChangeCurrency(selectedOption);
                        }}
                      />
                    </Col>
                    <Col xs={12} className="field-ticket-date">
                      <InputDateTime
                        placeholder={
                          item.ticketType === TicketTypeEnum.EarlyBird
                            ? t("secondStep.endSales")
                            : t("secondStep.startSales")
                        }
                        name={`tickets[${itemIndex}].date`}
                        className="overflow-hidden"
                        max={values.startDate.toString()}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row className="g-3 mb-3">
                    <Col lg={3}>
                      <InputNumber
                        name={`tickets[${itemIndex}].quantity`}
                        placeholder={t("secondStep.quantity")}
                        min={1}
                      />
                    </Col>
                    <Col lg={3}>
                      <div className="position-relative hide-arrows">
                        <InputNumber
                          name={`tickets[${itemIndex}].limitQuantity`}
                          placeholder={t("secondStep.limitQuantity")}
                          min={1}
                          max={20}
                        />
                        <InfoTooltip />
                      </div>
                    </Col>
                    <Col xs={6} lg={4}>
                      <InputNumber
                        name={`tickets[${itemIndex}].price`}
                        placeholder={t("secondStep.price")}
                        min={0}
                        disabled={item.ticketType === "free"}
                      />
                    </Col>
                    <Col xs={6} lg={2}>
                      <SelectInput
                        options={currenciesOptions}
                        label={t("secondStep.currency")}
                        name={`tickets[${itemIndex}].currency`}
                        isDisabled={item.ticketType === "free"}
                        value={currenciesOptions.find(
                          (option) =>
                            option.label === values.tickets[itemIndex].currency
                        )}
                        onChange={(selectedOption: SelectOption | null) => {
                          handleChangeCurrency(selectedOption);
                        }}
                      />
                    </Col>
                  </Row>
                )}

                <InputTextArea
                  placeholder={t("secondStep.description")}
                  name={`tickets[${itemIndex}].description`}
                  height={86}
                />
              </div>
            </div>
          );
        })}
    </>
  );
}
