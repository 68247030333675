import React from "react";
import { Pencil, Trash } from "react-bootstrap-icons";
import { IImageGalery } from "../../../../types";

interface IGaleryItemProps {
  item: IImageGalery;
  handleEditGaleryItem: (id: string) => Promise<void>;
  handleDeleteGaleryItem: (id: string) => Promise<void>;
}

export default function GaleryItem({
  item,
  handleEditGaleryItem,
  handleDeleteGaleryItem,
}: IGaleryItemProps) {
  return (
    <>
      <img
        src={item.image}
        alt="galery item"
        className="rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div className="position-absolute top-50 start-50 translate-middle d-flex gap-1">
        <div
          className="rounded-circle bg-white d-flex justify-content-center align-items-center cursor-pointer"
          style={{ width: 22, height: 22 }}
          onClick={() => handleEditGaleryItem(item.id)}
        >
          <Pencil width={12} height={12} />
        </div>
        <div
          className="rounded-circle bg-white d-flex justify-content-center align-items-center cursor-pointer"
          style={{ width: 22, height: 22 }}
          onClick={() => handleDeleteGaleryItem(item.id)}
        >
          <Trash width={12} height={12} />
        </div>
      </div>
    </>
  );
}
