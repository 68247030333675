import React, { useEffect, useRef } from "react";
import Wrapper from "../../components/wrapper";
import StepPagination from "../../components/stepPagination";
import { useTranslation } from "react-i18next";
import { InfoCircle } from "react-bootstrap-icons";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import classNames from "classnames";
import { Formik, Form as FormikForm, FormikHelpers, FormikProps } from "formik";
import { IFirstProps } from "../first";
import {
  EndRecurrenceEnum,
  IEntity,
  NEW_PLACE,
  RepeatPeriodEnum,
  TicketTypeEnum,
} from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { validationSchemaStep2 } from "../../validations";
import TicketType from "./ticketType";
import TicketReturn from "./ticketReturn";
import ReturnContacts from "./returnContacts";
import {
  useCreateEventMutation,
  useGetTicketTypesQuery,
  useUpdateEventMutation,
} from "../../qraphql/event.hooks";
import {
  EventRecurrenceType,
  NewContactTypeInput,
  NewEventTypeInput,
  NewPerformerTypeInput,
  NewPlaceInput,
  NewPlatformInput,
  PerformersInput,
  PlaceInput,
} from "../../../graphql/types";
import {
  clearLocalStorageItems,
  filesToBlobs,
  getDayNumber,
  getRrulePeriod,
  getRruleWeekDayFromWeekArray,
  isLocalStorageData,
} from "../../helpers";
import ApolloError from "../../components/apolloError";
import { Options, RRule, Weekday } from "rrule";
import useGetEventData from "../../hooks/useGetEventData";
import EventLoadingWrapper from "../../components/wrapper/EventLoadingWrapper";
import ErrorEventLoadingWrapper from "../../components/wrapper/ErrorEventLoadingWrapper";
import ScrollToFieldError from "../../components/ScrollToFieldError";
import useGetUrlLocale from "../../hooks/useGetUrlLocale";

export interface ITicketTypesTitles {
  [key: string]: string;
}

interface IisOnlineEvent {
  platform: NewPlatformInput;
}

interface IisNotOnlineEvent {
  place: PlaceInput;
}

interface INewPlace {
  newPlace: NewPlaceInput;
}

interface IContacts {
  contacts: NewContactTypeInput;
}

interface IRecurrence {
  recurrence: EventRecurrenceType;
}

interface IPerformers {
  performers: PerformersInput;
}

interface INewPerformers {
  newPerformers: NewPerformerTypeInput[];
}

export default function Second({
  entity,
  handleUpdateEntity,
  step,
}: IFirstProps) {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<IEntity>>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const urlLocale = useGetUrlLocale();
  const { data: ticketTypesData, loading: ticketTypesLoading } =
    useGetTicketTypesQuery();
  const [
    createEvent,
    { data: createEventData, loading: createEventLoading, error: createError },
  ] = useCreateEventMutation();
  const [
    updateEvent,
    { data: updateEventData, loading: updateEventLoading, error: updateError },
  ] = useUpdateEventMutation();

  const {
    getEventData,
    handleTryFetchImages,
    isEventLoading,
    errorLoadImageFiles,
    eventError,
  } = useGetEventData({ handleUpdateEntity, step: 1 });

  useEffect(() => {
    const isNoUpdateParam = new URLSearchParams(window.location.search).has(
      "noupdate"
    );
    if (
      id &&
      isLocalStorageData() &&
      (Number(id) !==
        (JSON.parse(localStorage.getItem("entity")) as IEntity).id ||
        !isNoUpdateParam)
    ) {
      // clear local storage if eventId in url is not equal eventId in storage, need get updated event data
      clearLocalStorageItems();
    }
  }, []);

  useEffect(() => {
    if (id && !isLocalStorageData()) {
      getEventData();
    }
  }, []);

  useEffect(() => {
    if (ticketTypesData && !isEventLoading) {
      ticketTypesData.ticketTypesProxy.forEach((item) => {
        const tiketIndex = formRef.current.values.tickets.findIndex(
          (el) => el.ticketType === item.name.toLowerCase()
        );

        if (tiketIndex !== -1) {
          formRef.current.setFieldValue(
            `tickets[${tiketIndex}].typeId`,
            item.id
          );
        }
      });
    }
  }, [ticketTypesData, isEventLoading]);

  useEffect(() => {
    if (createEventData) {
      handleUpdateEntity(
        {
          ...formRef.current.values,
          id: createEventData.createEvent.id,
          galery:
            createEventData.createEvent.galleryImages.length > 0 &&
            formRef.current.values.galery &&
            formRef.current.values.galery.length > 0
              ? createEventData.createEvent.galleryImages.map((item, i) => ({
                  ...formRef.current.values.galery[i],
                  id: String(item.id),
                }))
              : null,
          banner: createEventData.createEvent?.bannerImage
            ? {
                ...formRef.current.values.banner,
                id: String(createEventData.createEvent.bannerImage.id),
              }
            : null,
        },
        2
      );
      navigate(
        `${urlLocale}/payment-info/${createEventData.createEvent.id}/edit`
      );
    } else if (updateEventData) {
      handleUpdateEntity(
        {
          ...formRef.current.values,
          id: updateEventData.updateEvent.id,
          galery:
            updateEventData.updateEvent.galleryImages.length > 0 &&
            formRef.current.values.galery &&
            formRef.current.values.galery.length > 0
              ? updateEventData.updateEvent.galleryImages.map((item, i) => ({
                  ...formRef.current.values.galery[i],
                  id: String(item.id),
                }))
              : null,
          banner: updateEventData.updateEvent?.bannerImage
            ? {
                ...formRef.current.values.banner,
                id: String(updateEventData.updateEvent.bannerImage.id),
              }
            : null,
        },
        2
      );
      navigate(
        `${urlLocale}/payment-info/${updateEventData.updateEvent.id}/edit`
      );
    }
    // eslint-disable-next-line
  }, [createEventData, updateEventData]);

  const ticketTypesTitles: ITicketTypesTitles = {
    [TicketTypeEnum.Regular]: t("secondStep.regular"),
    [TicketTypeEnum.Free]: t("secondStep.free"),
    [TicketTypeEnum.Vip]: t("secondStep.vip"),
    [TicketTypeEnum.FanZone]: t("secondStep.fanZone"),
    [TicketTypeEnum.Balcony]: t("secondStep.balcony"),
    [TicketTypeEnum.Child]: t("secondStep.child"),
    [TicketTypeEnum.Student]: t("secondStep.student"),
    [TicketTypeEnum.Senior]: t("secondStep.senior"),
    [TicketTypeEnum.MerchBundle]: t("secondStep.merchBundle"),
    [TicketTypeEnum.SingleDayPass]: t("secondStep.singleDayPass"),
    [TicketTypeEnum.WeekendPass]: t("secondStep.weekendPass"),
    [TicketTypeEnum.EarlyBird]: t("secondStep.earlyBird"),
    [TicketTypeEnum.LastMinute]: t("secondStep.lastMinute"),
  };

  const onSubmit = async (
    values: IEntity,
    { setSubmitting }: FormikHelpers<IEntity>
  ) => {
    const isOnlineEvent: IisOnlineEvent | object = values.isOnline
      ? {
          platform: {
            invitationLink: values.eventInvitationLink,
            platformUseGuidelines: values.guidelinesPlatformUse,
          },
        }
      : {};

    const place: INewPlace | IisNotOnlineEvent | object = !values.isOnline
      ? values.place === NEW_PLACE
        ? {
            newPlace: {
              address: values.address,
              name: values.placeName,
              city: { id: Number(values.city) },
            },
          }
        : { place: { id: Number(values.place) } }
      : {};

    const contacts: IContacts | object = values.isReturnable
      ? {
          contacts: values.contacts.map((item) => ({
            email: item.email,
            phone: item.phone ? `+${item.phone}` : "",
          })),
        }
      : {};

    let ruleOptions: Partial<Options>;
    switch (values.repeats) {
      case RepeatPeriodEnum.Daily:
        ruleOptions = {
          freq: RRule.DAILY,
        };
        break;
      case RepeatPeriodEnum.Weekly:
        ruleOptions = {
          freq: RRule.WEEKLY,
          byweekday: [getRRuleWeekdayFromDate(new Date(values.startDate))],
        };
        break;
      case RepeatPeriodEnum.EveryWeekday:
        ruleOptions = {
          freq: RRule.WEEKLY,
          byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
        };
        break;
      case RepeatPeriodEnum.Monthly:
        ruleOptions = {
          freq: RRule.MONTHLY,
          byweekday: [getRRuleWeekdayFromDate(new Date(values.startDate))],
          bysetpos: getDayNumber(new Date(values.startDate), t).count,
        };
        break;
      case RepeatPeriodEnum.Yearly:
        ruleOptions = {
          freq: RRule.YEARLY,
          bymonth: new Date(values.startDate).getMonth() + 1,
        };
        break;
      case RepeatPeriodEnum.Custom:
        ruleOptions = {
          freq: getRrulePeriod(
            values.recurrence.repeatsType as RepeatPeriodEnum
          ),
          interval: values.recurrence.repeatsEvery,
          until:
            values.recurrence.endRecurrence === EndRecurrenceEnum.OnDate &&
            values.recurrence.onDate
              ? new Date(values.recurrence.onDate)
              : null,
          ...(values.recurrence.repeatsType === RepeatPeriodEnum.Weekly
            ? {
                byweekday: getRruleWeekDayFromWeekArray(
                  values.recurrence.weekDays
                ),
              }
            : {}),
          ...(values.recurrence.endRecurrence === EndRecurrenceEnum.After
            ? { count: values.recurrence.endAfterOccurences }
            : {}),
        };
        break;
    }

    const rrule = new RRule({
      ...ruleOptions,
    });

    const recurrence: IRecurrence | object = values.isRecurring
      ? {
          recurrence: {
            startDate: values.startDate,
            endDate: values.endDate,
            rrule: rrule.toString(),
          },
        }
      : {};

    const newPerformers: INewPerformers | object =
      values.newPerformers.length > 0
        ? {
            newPerformers: values.newPerformers.map((item) => ({
              name: item,
            })),
          }
        : {};

    const performers: IPerformers | object =
      values.performers.length > 0
        ? {
            performers: { ids: values.performers.map((item) => Number(item)) },
          }
        : {};

    const createUpdateEventData: NewEventTypeInput = {
      ...isOnlineEvent,
      ...recurrence,
      ...place,
      ...performers,
      ...newPerformers,
      ...(values.timezoneId.length
        ? { timezone: { id: Number(values.timezoneId) } }
        : {}),
      title: values.title,
      description: values.description,
      categories: { ids: values.categories.map((item) => Number(item)) },
      startDate: values.startDate,
      endDate: values.endDate,
      isRecurring: values.isRecurring,
      isOnline: values.isOnline,
      ticketReturn: {
        isPossible: values.isReturnable,
        ...contacts,
        description: values.returnDescription,
      },
      tickets: values.tickets
        .filter((el) => el.active)
        .map((item) => {
          const startTicketSales =
            item.ticketType === TicketTypeEnum.LastMinute
              ? { startTicketSales: item.date }
              : {};
          const endTicketSales =
            item.ticketType === TicketTypeEnum.EarlyBird
              ? { endTicketSales: item.date }
              : {};
          const price =
            item.ticketType === TicketTypeEnum.Free
              ? {}
              : { price: item.price };
          return {
            type: { id: Number(item.typeId) },
            ...(item.id && { id: item.id }),
            quantity: item.quantity,
            limitQuantity: item.limitQuantity,
            currency: item.currency,
            description: item.description,
            ...startTicketSales,
            ...endTicketSales,
            ...price,
          };
        }),
    };

    let blobPoster: Blob = null;
    let blobGallery: Blob[] = null;
    let blobBanner: Blob = null;
    if (values.poster) {
      const [data] = await filesToBlobs([values.poster]);
      blobPoster = data;
    }
    if (values.galery && !id) {
      blobGallery = await filesToBlobs(values.galery.map((item) => item.file));
    } else if (values.galery && id) {
      const newGalerImages = values.galery.filter((el) =>
        el.id.includes("image")
      );
      if (newGalerImages.length > 0) {
        blobGallery = await filesToBlobs(
          newGalerImages.map((item) => item.file)
        );
        formRef.current.setFieldValue(
          "galery",
          formRef.current.values.galery.map((item) => ({
            ...item,
            id: item.id.replace("image", ""),
          }))
        );
      }
    }
    if (values.banner) {
      const [data] = await filesToBlobs([values.banner.file]);
      blobBanner = data;
    }

    try {
      if (id || values.id) {
        await updateEvent({
          variables: {
            eventId: Number(id) || values.id,
            event: createUpdateEventData,
            poster: blobPoster,
            gallery: blobGallery,
            banner: blobBanner,
          },
        });
      } else {
        await createEvent({
          variables: {
            event: createUpdateEventData,
            poster: blobPoster,
            gallery: blobGallery,
            banner: blobBanner,
          },
        });
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  if (isEventLoading) {
    return (
      <EventLoadingWrapper
        step={step}
        title={t("firstStep.createEvent")}
        stepName={t("secondStep.ticketTypes")}
      />
    );
  }

  if (eventError || errorLoadImageFiles) {
    return (
      <ErrorEventLoadingWrapper
        step={step}
        title={t("firstStep.createEvent")}
        errorLoadImageFiles={errorLoadImageFiles}
        eventError={eventError}
        handleTryFetchImages={() => handleTryFetchImages(entity)}
      />
    );
  }

  return (
    <Wrapper>
      <h1 className="fw-bold fs-1 mb-4 mb-md-5">
        {t("firstStep.createEvent")}
      </h1>
      <StepPagination step={step} />
      <div className="fw-bold fs-4 mb-3">{t("secondStep.ticketTypes")}</div>
      <div className="bg-light-gray text-info p-3 rounded d-flex mb-3">
        <div>
          <InfoCircle className="pe-2 fs-4" height={26} width={26} />
        </div>
        {t("secondStep.ticketInfo")}
      </div>

      <Formik
        innerRef={formRef}
        initialValues={entity}
        onSubmit={onSubmit}
        validationSchema={validationSchemaStep2(t)}
        enableReinitialize
      >
        {({ isSubmitting, isValid, submitCount, values, setFieldValue }) => {
          return (
            <FormikForm>
              <ScrollToFieldError />
              <div className="d-flex flex-wrap gap-3 mb-5">
                {values.tickets.map((item, index) => {
                  return (
                    <Form.Label
                      key={item.ticketType}
                      htmlFor={`inline-radio-${item.ticketType}`}
                      style={{ padding: "4px 10px" }}
                      className={classNames(
                        "rounded border border-1 mb-0 text-success",
                        {
                          "bg-success-light text-success border-success":
                            item.active,
                          "text-secondary text-light-blue border-light-blue":
                            !item.active,
                        }
                      )}
                    >
                      <Form.Check
                        type="checkbox"
                        label={ticketTypesTitles[item.ticketType]}
                        onChange={(e) =>
                          setFieldValue(
                            `tickets[${index}].active`,
                            e.target.checked
                          )
                        }
                        checked={item.active}
                        id={`inline-radio-${item.ticketType}`}
                        disabled={
                          values.isRecurring &&
                          (item.ticketType === TicketTypeEnum.EarlyBird ||
                            item.ticketType === TicketTypeEnum.LastMinute)
                        }
                      />
                    </Form.Label>
                  );
                })}
              </div>
              {values.tickets.filter((item) => item.active).length === 0 ? (
                <div className="bg-warning-light text-warning p-3 rounded d-flex mb-3">
                  <div>
                    <InfoCircle className="pe-2 fs-4" height={26} width={26} />
                  </div>
                  {t("secondStep.selectTicketType")}
                </div>
              ) : (
                <TicketType ticketTypesTitles={ticketTypesTitles} />
              )}
              <TicketReturn />
              {values.isReturnable && <ReturnContacts />}

              <Row className="justify-content-between my-5">
                <Col xs={4} lg={3}>
                  <Button
                    variant="outline-success"
                    className="w-100 py-3 fw-normal back-btn"
                    onClick={() => {
                      handleUpdateEntity({ ...entity, ...values }, 0);
                      id
                        ? navigate(
                            `${urlLocale}/create-event/${id}/edit?noupdate=true`
                          )
                        : navigate(`${urlLocale}/create-event?noupdate=true`);
                    }}
                  >
                    {t("secondStep.back")}
                  </Button>
                </Col>
                <Col xs={8} lg={4}>
                  <Button
                    variant="success"
                    className="fw-bold w-100 py-3"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      (submitCount > 0 && !isValid) ||
                      ticketTypesLoading
                    }
                  >
                    {t("firstStep.next")}
                    {(createEventLoading || updateEventLoading) && (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="ms-2"
                      />
                    )}
                  </Button>
                </Col>
              </Row>
            </FormikForm>
          );
        }}
      </Formik>
      <ApolloError networkError={createError?.networkError} />
      <ApolloError networkError={updateError?.networkError} />
    </Wrapper>
  );
}

function getRRuleWeekdayFromDate(date: Date): Weekday {
  const weekdayMap: { [key: number]: Weekday } = {
    0: RRule.SU,
    1: RRule.MO,
    2: RRule.TU,
    3: RRule.WE,
    4: RRule.TH,
    5: RRule.FR,
    6: RRule.SA,
  };

  const day: number = date.getDay();
  return weekdayMap[day];
}
