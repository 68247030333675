import { ApolloError } from "@apollo/client";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Wrapper from ".";
import StepPagination from "../stepPagination";

interface IErrorEventLoadingWrapperProps {
  step: number;
  title: string;
  errorLoadImageFiles: boolean;
  eventError: ApolloError;
  handleTryFetchImages: () => Promise<void>;
  accessError?: boolean;
}

export default function ErrorEventLoadingWrapper({
  step,
  title,
  errorLoadImageFiles,
  eventError,
  accessError,
  handleTryFetchImages,
}: IErrorEventLoadingWrapperProps) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h1 className="fw-bold fs-1 mb-4 mb-md-5">{title}</h1>
      <StepPagination step={step} />
      {accessError ? (
        <div className="bg-danger bg-opacity-10 text-danger p-3 rounded">
          You need to be logged as organizer to access this page
        </div>
      ) : null}
      {errorLoadImageFiles ? (
        <>
          <div className="bg-danger bg-opacity-10 text-danger p-3 rounded">
            {t("firstStep.Error loading images")}
          </div>
          <div className="text-center mt-3">
            <Button variant="success" onClick={handleTryFetchImages}>
              {t("firstStep.Try Again")}
            </Button>
          </div>
        </>
      ) : null}
      {eventError ? (
        <div className="bg-danger bg-opacity-10 text-danger p-3 rounded">
          {eventError?.message}
        </div>
      ) : null}
    </Wrapper>
  );
}
