import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Stripe from "../img/Stripe";
import {
  GetOrganizerPaymentsQuery,
  useSetPaymentStripeMutation,
} from "../../../../qraphql/event.hooks";
import { IEntity, PaymentTypeEnum } from "../../../../types";
import ApolloError from "../../../../components/apolloError";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import useGetUrlLocale from "../../../../hooks/useGetUrlLocale";

interface ICountryModalProps {
  handleClose: () => void;
  organizerPayments: GetOrganizerPaymentsQuery;
  handleUpdateEntity: (values: IEntity, currentStep: number) => Promise<void>;
}

export default function StripeModal({
  handleClose,
  organizerPayments,
  handleUpdateEntity,
}: ICountryModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlLocale = useGetUrlLocale();
  const { values } = useFormikContext<IEntity>();
  const organizerPaymentStripe = organizerPayments.organizer.payments.find(
    (item) => item.type === PaymentTypeEnum.Stripe
  );
  const [setPaymentStripe, { data, loading, error }] =
    useSetPaymentStripeMutation({
      variables: {
        eventId: values.id,
        paymentId: organizerPaymentStripe?.id,
        returnUrl: organizerPaymentStripe?.stripeConnectBankAccountId
          ? `${process.env.REACT_APP_TICKET_ADMIN_URL}${urlLocale}/check-publish`
          : `${process.env.REACT_APP_TICKET_ADMIN_URL}${urlLocale}/payment-info/connect/${values.id}`,
      },
    });

  const countryCode = organizerPayments.organizer.payments.find(
    (item) => item.type === PaymentTypeEnum.Stripe
  )?.country;

  useEffect(() => {
    setPaymentStripe();
    handleUpdateEntity(
      {
        ...values,
        bankConnect: {
          ...values.bankConnect,
          selectedStripeCountryCode: countryCode,
        },
      },
      2
    );
    // eslint-disable-next-line
  }, []);

  const handleChangeStripe = async () => {
    window.location.href = data.setPaymentStripe.redirectUrl;
    if (data.setPaymentStripe.paymentsProxyType?.stripeConnectBankAccountId) {
      handleUpdateEntity(values, 3);
    }
    handleClose();
  };

  const handleContinueEvent = async () => {
    if (data.setPaymentStripe.paymentsProxyType?.stripeConnectBankAccountId) {
      navigate(`${urlLocale}/check-publish`);
      handleUpdateEntity(values, 3);
    }
    handleClose();
  };

  return (
    <>
      <Modal show={true} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            <Stripe />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3 mb-3">
            <div className="text-center mb-3 text-info">
              {t("thirdStep.savedStripe")}
            </div>
            <div className="mb-3">
              {t("thirdStep.email")}: {organizerPayments.organizer?.email}
            </div>
            <div className="mb-3">
              {t("thirdStep.selectedCountry")}: {countryCode}
            </div>
            <Button
              variant="stripe"
              className="fw-bold py-3 px-5 w-100"
              onClick={handleContinueEvent}
              disabled={loading}
            >
              {t("thirdStep.continueEvent")}
            </Button>
            <div className="d-flex align-items-center py-3 px-0">
              <hr className="w-100 text-info" />
              <p className="text-info px-2 mb-0">{t("thirdStep.or")}</p>
              <hr className="w-100 text-info" />
            </div>
            <Button
              variant="outline-stripe"
              className="py-3 px-5 w-100 fw-normal"
              onClick={handleChangeStripe}
              disabled={loading}
            >
              {t("thirdStep.changeStripe")}
            </Button>
          </div>

          <ApolloError networkError={error?.networkError} />
        </Modal.Body>
      </Modal>
    </>
  );
}
