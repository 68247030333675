import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InputText from "../../../components/inputs/inputText";
import SelectInput from "../../../components/inputs/select";
import {
  useGetCountriesQuery,
  useGetCitiesbyCountryIdLazyQuery,
  useGetPlacesByCityIdLazyQuery,
} from "../../../qraphql/event.hooks";
import { useFormikContext } from "formik";
import { IEntity, NEW_PLACE, SelectOption } from "../../../types";
import { useTranslation } from "react-i18next";
import { InfoCircle } from "react-bootstrap-icons";

export default function OfflineFields() {
  const { t } = useTranslation();
  const { handleBlur, setFieldValue, values, setFieldTouched } =
    useFormikContext<IEntity>();
  const { data: countriesData } = useGetCountriesQuery();
  const [getCities, { data: citiesData }] = useGetCitiesbyCountryIdLazyQuery();
  const [getPlaces, { data: placesData }] = useGetPlacesByCityIdLazyQuery();

  const optionsCountries =
    countriesData?.countries
      .map((item) => ({
        value: item.id.toString(),
        label: item.country,
        currency: item?.currency || "USD",
        code: item?.code || "us",
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const optionsCities =
    citiesData?.country.cities
      .map((item) => ({
        value: item.id.toString(),
        label: item.city,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const optionsPlaces =
    placesData?.placesByCityId.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    })) || [];

  useEffect(() => {
    if (!values.country) return;
    getCities({ variables: { countryId: parseInt(values.country) } });
  }, [values.country, getCities]);

  useEffect(() => {
    if (!values.city) return;
    getPlaces({ variables: { cityId: parseInt(values.city) } });
  }, [values.city, getPlaces]);

  return (
    <>
      <div className="fw-bold fs-4 mb-4">{t("firstStep.venueLocation")}</div>
      <Row className="g-3">
        <Col xs={12} md={4}>
          <SelectInput
            options={optionsCountries}
            placeholder={t("firstStep.selectCountry")}
            label={t("firstStep.country")}
            name="country"
            onBlur={() => setFieldTouched("country", true)}
            onChange={(
              selectedOption:
                | (SelectOption & { currency: string; code: string })
                | null
            ) => {
              setFieldValue(
                "country",
                selectedOption ? selectedOption.value : ""
              );
              setFieldValue("city", "");
              setFieldValue("place", "");
              values.tickets.forEach((_, index) => {
                setFieldValue(
                  `tickets[${index}].currency`,
                  selectedOption ? selectedOption.currency : "USD"
                );
              });
              setFieldValue(
                "countryCode",
                selectedOption ? selectedOption.code.toLocaleLowerCase() : "us"
              );
            }}
            value={optionsCountries.find(
              (option) => option.value === values.country
            )}
          />
        </Col>
        <Col xs={12} md={4}>
          <SelectInput
            options={optionsCities}
            placeholder={t("firstStep.selectCity")}
            label={t("firstStep.city")}
            name="city"
            onBlur={() => setFieldTouched("city", true)}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue("place", "");
              setFieldValue("city", selectedOption ? selectedOption.value : "");
            }}
            isDisabled={!citiesData}
            value={
              optionsCities.find((option) => option.value === values.city) ||
              null
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <SelectInput
            options={[
              { value: NEW_PLACE, label: t("firstStep.Add new place") },
              ...optionsPlaces,
            ]}
            placeholder={t("firstStep.selectPlace")}
            label={t("firstStep.place")}
            name="place"
            onBlur={() => setFieldTouched("place", true)}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "place",
                selectedOption ? selectedOption.value : ""
              );
            }}
            isDisabled={!placesData || !values.city}
            value={
              optionsPlaces.find((option) => option.value === values.place) ||
              null
            }
          />
        </Col>
        {values.place === NEW_PLACE && (
          <>
            <Col xs={12} md={6}>
              <InputText
                placeholder={t("firstStep.placeName")}
                name="placeName"
                onBlur={(e) => {
                  setFieldTouched("address", true);
                  handleBlur(e);
                }}
              />
            </Col>
            <Col xs={12} md={6}>
              <InputText placeholder={t("firstStep.address")} name="address" />
            </Col>
          </>
        )}
      </Row>
      <div className="bg-light-gray text-info p-3 rounded d-flex mt-3">
        <div>
          <InfoCircle className="pe-2 fs-4" height={26} width={26} />
        </div>
        <div>
          {t(
            "firstStep.If you didn't find your country or city in the proposed list, please"
          )}{" "}
          <a
            href={`${process.env.REACT_APP_TICKET_URL}/organizer-support-chat`}
            target="_blank"
          >
            {t("firstStep.contact support")}
          </a>{" "}
          {t("firstStep.to add the value you need.")}
        </div>
      </div>
    </>
  );
}
