import { TFunction } from "i18next";
import { RRule, Weekday } from "rrule";
import {
  EndRecurrenceEnum,
  IEventRecurrence,
  RepeatPeriodEnum,
} from "../types";

export const base64ToFile = (base64String: string, filename: string): File => {
  const byteString = atob(base64String.split(",")[1]);
  const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
  const byteNumbers: number[] = new Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const fileType = mimeString.split("/")[1];

  return new File([byteArray], `${filename}.${fileType}`, {
    type: mimeString,
  });
};

export const imageFileToBase64 = (imageFile: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!(imageFile instanceof File)) {
      return null;
    }

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const filesToBlobs = (files: File[]): Promise<Blob[]> =>
  Promise.all(
    files.map(
      (file) =>
        new Promise<Blob>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result instanceof ArrayBuffer) {
              resolve(new Blob([reader.result], { type: file.type }));
            } else {
              reject(new Error("Failed to read file as ArrayBuffer."));
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file."));
          };
          reader.readAsArrayBuffer(file);
        })
    )
  );

export const clearLocalStorageItems = () => {
  ["entity", "options", "poster"].forEach((item) => {
    localStorage.removeItem(item);
  });
};

export function formatDateTime(dateString: string): string {
  const currentDate = new Date(dateString);
  const year = currentDate.getUTCFullYear();
  const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getUTCDate().toString().padStart(2, "0");
  const hours = currentDate.getUTCHours().toString().padStart(2, "0");
  const minutes = currentDate.getUTCMinutes().toString().padStart(2, "0");
  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formattedDateTime;
}

export const getFileFromUrl = async (fileUrl: string): Promise<File> => {
  const getUrlExtension = (url: string): string => {
    return url.split(/[#?]/)[0].split(".").pop()!.trim();
  };
  const imgExt = getUrlExtension(fileUrl);

  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const file = new File([blob], "image." + imgExt, {
      type: blob.type,
    });
    return file;
  } catch (error) {
    console.error("Error fetching the file:", error);
    throw error;
  }
};

export function getDayNumber(
  date: Date,
  t: TFunction
): {
  count: number;
  nameCount: string;
} {
  const day = date.getDate();
  const n = Math.ceil(day / 7);

  if (n >= 11 && n <= 13) {
    return { count: 1, nameCount: t("firstStep.first") };
  }

  switch (n % 10) {
    case 1:
      return { count: 1, nameCount: t("firstStep.first") };
    case 2:
      return { count: 2, nameCount: t("firstStep.second") };
    case 3:
      return { count: 3, nameCount: t("firstStep.third") };
    case 4:
      return { count: 4, nameCount: t("firstStep.fourth") };
    case 5:
      return { count: 5, nameCount: t("firstStep.fifth") };
    default:
      return { count: 1, nameCount: t("firstStep.first") };
  }
}

export const getRrulePeriod = (period: RepeatPeriodEnum) => {
  switch (period) {
    case RepeatPeriodEnum.Daily:
      return RRule.DAILY;
    case RepeatPeriodEnum.Weekly:
      return RRule.WEEKLY;
    case RepeatPeriodEnum.Monthly:
      return RRule.MONTHLY;
    case RepeatPeriodEnum.Yearly:
      return RRule.YEARLY;
    default:
      RRule.WE;
      return RRule.DAILY;
  }
};

export const getRruleWeekDayFromWeekArray = (weekArr: boolean[]) => {
  const indexToWeekday = [
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
    RRule.SU,
  ];
  return weekArr
    .map((include, index) => (include ? indexToWeekday[index] : null))
    .filter((weekday) => weekday !== null);
};

export const freqRuleToPeriodEnumString = (
  rrule: RRule,
  isCustomMode = false
): string => {
  const freq = rrule.options.freq;
  switch (freq) {
    case RRule.DAILY:
      return RepeatPeriodEnum.Daily;
    case RRule.WEEKLY:
      return rrule.options.byweekday.length > 1 && !isCustomMode
        ? RepeatPeriodEnum.EveryWeekday
        : RepeatPeriodEnum.Weekly;
    case RRule.MONTHLY:
      return RepeatPeriodEnum.Monthly;
    case RRule.YEARLY:
      return RepeatPeriodEnum.Yearly;
  }
};

export const getReccurrenceFromRule = (rrule: RRule): IEventRecurrence => {
  const weekdayIndices: { [key: string]: number } = {
    MO: 0,
    TU: 1,
    WE: 2,
    TH: 3,
    FR: 4,
    SA: 5,
    SU: 6,
  };

  const byWeekdays = Array.isArray(rrule.origOptions.byweekday)
    ? rrule.origOptions.byweekday
    : rrule.origOptions.byweekday
      ? [rrule.origOptions.byweekday]
      : [];

  const weekDays = new Array(7).fill(false);
  byWeekdays.forEach((weekday: Weekday) => {
    weekDays[weekdayIndices[weekday.toString()]] = true;
  });

  return {
    repeatsEvery: rrule.options.interval,
    repeatsType: freqRuleToPeriodEnumString(rrule, true),
    weekDays,
    endRecurrence: rrule.options.count
      ? EndRecurrenceEnum.After
      : rrule.options.until
        ? EndRecurrenceEnum.OnDate
        : EndRecurrenceEnum.NeverStop,
    onDate: rrule.options.until
      ? new Date(rrule.options.until).toISOString().split("T")[0]
      : null,
    endAfterOccurences: rrule.options.count || 1,
  };
};

export const isLocalStorageData = () =>
  localStorage.getItem("entity") &&
  localStorage.getItem("poster") &&
  localStorage.getItem("options");
